@import "variables";
@import "framework/framework";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/fontawesome";
@import "../../node_modules/@fortawesome/fontawesome-pro/scss/brands";

html {
	background: $color-black url('../img/bg/BG.png') no-repeat center top;
	background-size: cover;
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
	font-size: 16px;
	line-height: 1;
	text-align: center;

	@include desktop-sm-down {
		font-size: 15px;
	}

	@include tablet-down {
		font-size: 14px;
	}

	@include phone-down {
		font-size: 13px;
	}

	@include phone-sm-down {
		font-size: 12px;
	}
}

body {
	color: $color-white;
}

a {
	color: $color-white;

	@include hover-focus {
		color: $color-white;
	}
}

header {
	padding: rem(45) 0 rem(65) 0;

	h1 {
		margin: 0;

		> img {
			margin: 0 auto;
		}
	}
}

main {
	.video {
		border: 1px solid $color-gray;

		+ .video {
			margin-top: rem(60);
		}
	}
}

footer {
	padding: rem(65) 0;

	.socials {
		list-style: none;
		margin: 0;
		padding: 0;

		> li {
			display: inline-block;
			font-size: rem(24);
			vertical-align: middle;

			+ li {
				margin-left: rem(20);
			}

			> a {
				color: $color-white;

				@include hover-focus {
					color: $color-gray;
				}
			}
		}
	}
}
